
export default function loginForm() {

    function openTabLogin(tabNumber) {
        var tabs = document.querySelectorAll('.login_tab');
        var contents = document.querySelectorAll('.login_content');

        tabs.forEach(function (tab) {
            tab.classList.remove('active');
        });
        contents.forEach(function (content) {
            content.style.display = 'none';
            content.classList.remove('active');
        });

        var activeTab = document.querySelector('.login_tab[data-tab-login="' + tabNumber + '"]');
        var activeContent = document.getElementById('login_content' + tabNumber);

        if (activeTab && activeContent) {
            activeTab.classList.add('active');
            activeContent.style.display = 'block';
            activeContent.classList.add('active');
        }
    }

    var tabs = document.querySelectorAll('.login_tab');
    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            let tabNumber = this.getAttribute('data-tab-login');
            openTabLogin(tabNumber);
        });
    });

    document.querySelector('.form-group-account_link').addEventListener('click', function (e) {
        e.preventDefault();
        openTabLogin('4');
    });

    let account = document.querySelectorAll('.account');
    let accountOrder = document.querySelector('.ordering .exit-account-btn');
    let loginClose = document.querySelector('.login_close');
    let loginWrapper = document.querySelector('.login_wrapper');


    account.forEach(function (tab) {
        tab.addEventListener('click', function (e) {
            e.preventDefault();
            let loginWrapper = document.querySelector('.login_wrapper');
            loginWrapper.classList.toggle('active');
            openTabLogin('4');
        });
    });



    loginClose.addEventListener('click', function (e) {
        loginWrapper.classList.toggle('active');
    });

    // loginWrapper.addEventListener('click', function(event) {
    //     if (!event.target.closest('.login_inner')) {
    //         loginWrapper.classList.remove('active');
    //     }
    // });

    document.querySelectorAll('.toggle-password').forEach(toggle => {
        toggle.addEventListener('click', function (e) {
            const passwordInput = e.target.closest('.form-group').querySelector('input');
            const hideEye = toggle.querySelector('.hide_eye');
            const openEye = toggle.querySelector('.open_eye');
            passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
            if(passwordInput.type === 'password') {
                hideEye.style.display = 'block';
                openEye.style.display = 'none';
            } else {
                hideEye.style.display = 'none';
                openEye.style.display = 'block';
            }
        });
    });


    let infoContent = document.querySelectorAll('.block-full-content p');
    if(infoContent) {
        infoContent.forEach(block => {
            if (block.clientHeight < block.scrollHeight) {
                block.classList.add('truncate');
            }
        });
    }


    document.addEventListener('DOMContentLoaded', function() {
        const registrationForm = document.querySelector('#login_content5 form');

        if (registrationForm) {
            registrationForm.addEventListener('submit', async function(e) {
                e.preventDefault();
                let hasError = false;

                // Функція для очищення попередніх повідомлень про помилки
                const clearPreviousErrors = () => {
                    document.querySelectorAll('.form-group-error').forEach(function(el) {
                        el.classList.remove('form-group-error');
                        const alert = el.querySelector('.alert.alert-danger');
                        if (alert) {
                            alert.remove();
                        }
                    });
                };

                // Функція для відображення помилки
                const showError = (selector, message) => {
                    const formGroup = document.querySelector(selector);
                    formGroup.classList.add('form-group-error');
                    const errorElement = document.createElement('span');
                    errorElement.classList.add('alert', 'alert-danger');
                    errorElement.textContent = message;
                    formGroup.appendChild(errorElement);
                    hasError = true;
                };

                clearPreviousErrors();

                // Перевірка імені
                const name = document.getElementById('emailSign');
                if (!name.value.trim()) {
                    showError('.form-group-name', 'Будь ласка, введіть ваше ім\'я.');
                }

                // Перевірка електронної пошти
                const email = document.getElementById('emailOrPhoneSign');
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!email.value.trim() || !emailPattern.test(email.value)) {
                    showError('.form-group-email-register', 'Неправильно введений Email');
                }

                const emailExists = await checkEmailExists(email);
                if (emailExists) {
                    showError('.form-group-email-register', 'Користувач з таким емейлом уже існує.');
                }

                // Перевірка номера телефону
                const phone = document.getElementById('phoneNumber');
                const phonePattern = /^\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
                if (!phone.value.trim() || !phonePattern.test(phone.value)) {
                    showError('.form-group-phone', 'Неправильно введений номер телефону');
                }

                const password = document.getElementById('passwordSign');
                const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W|_)[A-Za-z\d\W_]{8,}$/;

                if (!password.value.trim() || !passwordPattern.test(password.value)) {
                    let missingElements = [];
                    if (!/(?=.*[a-z])/.test(password.value)) missingElements.push("маленьку літеру");
                    if (!/(?=.*[A-Z])/.test(password.value)) missingElements.push("велику літеру");
                    if (!/(?=.*\d)/.test(password.value)) missingElements.push("цифру");
                    if (!/(?=.*[@$!%*?&])/.test(password.value)) missingElements.push("спеціальний символ");

                    showError('.form-group-password-register', `Пароль має містити не менше 8 символів, включати ${missingElements.join(", ")}.`);
                }


                // Перевірка повторення пароля
                const passwordRepeat = document.getElementById('passwordRepeatSign');
                if (password.value !== passwordRepeat.value) {
                    showError('.form-group-password-repeat', 'Паролі не співпадають.');
                }

                if (!hasError) {
                    // Відправка форми, якщо немає помилок
                    registrationForm.submit();
                }
            });
        }


        async function checkEmailExists() {
            let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const formData = new FormData(registrationForm);
            formData.append('_token', token);

            const response = await fetch('/user/check-email', {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                },
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            return data.exists;
        }

        async function checkPhoneExists() {
            let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const formData = new FormData(registrationForm);
            formData.append('_token', token);

            const response = await fetch('/user/check-phone', {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                },
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            return data.exists;
        }

    });


}
